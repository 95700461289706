import React from 'react'
import { graphql } from 'gatsby'
import Image from '../components/Image'
import Layout from '../components/Layout'
import Cartotheque from '../components/Cartotheque'
import Card from '../components/Card'

const WorkIndex = ({ data }) => {
  const seo = {
    title: 'Works',
    slug: 'work',
    image: data.seoImage,
    description: 'A carefully curated collection of Unsworn endeavours.'
  }

  return (
    <Layout seo={seo}>
      <article>
        <h1>Selected Works</h1>
        <Cartotheque>
          {data.works.edges.map(({ node: work }) => {
            return (
              <Card
                size={work.frontmatter.cardSize}
                link={`/work/${work.frontmatter.slug}`}
                key={work.frontmatter.slug}
              >
                <Image data={work.frontmatter.image} />
                <h2>{work.frontmatter.name}</h2>
                <p>{work.frontmatter.summary}</p>
              </Card>
            )
          })}
        </Cartotheque>
      </article>
    </Layout>
  )
}

export default WorkIndex

export const pageQuery = graphql`
  query WorkIndexQuery {
    seoImage: file(relativePath: { eq: "unsworn/everyday/mange_phone_scream.jpg" }) {
      ...seoImage
    }
    works: allMdx(
      sort: { fields: [frontmatter___sortOrder], order: ASC }
      filter: { fileAbsolutePath: { regex: "/content/works//" } }
    ) {
      edges {
        node {
          frontmatter {
            cardSize
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            slug
            summary
          }
        }
      }
    }
  }
`
